<template>
  <div class="statistics-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>数据统计</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="nav-wrap">
        <ul class="horizontal-nav">
          <li>
            <router-link to="/base/statistics/monitor">监测数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/sport">运动数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/train">训练数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/drug">服用数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/food">膳食数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/plan">方案数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/service">服务数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/quality">质检数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/boost">促进数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/doctor">医生促进</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/warning">预警数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/problem">问题数据</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/ranking">会员排名</router-link>
          </li>
          <li>
            <router-link to="/base/statistics/sick">疾病统计</router-link>
          </li>
        </ul>
      </div>
      <div class="content-in">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="css" scoped>
.nav-wrap {
  overflow-x: auto;
  overflow-y: hidden;
}

.horizontal-nav {
  height: 44px;
  background: #32323a;
  min-width: 1350px;
}

.horizontal-nav>li {
  float: left;
  height: 44px;
  line-height: 44px;
}

.horizontal-nav>li>a {
  display: block;
  padding: 0 20px;
  color: #999;
}

.horizontal-nav>li>a.active {
  color: #fff;
  background-color: #fdad00;
}

.statistics-container .content-in {
  padding: 20px;
  background-color: #fff;
}
</style>
